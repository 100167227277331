import axios from 'axios';

// const API_BASE_URL = 'http://localhost:8000'; 

const API_BASE_URL =  import.meta.env.VITE_APP_API_URL  || '/v1/api';
console.log('API_BASE_URL:', API_BASE_URL); // For debugging

export const fetchEpos = async (page = 1, limit = 5) => {
  const fullUrl = `${API_BASE_URL}/epos?page=${page}&limit=${limit}`;
  console.log('Full URL being requested:', fullUrl); // Log the complete URL before the request
  try {
    const response = await axios.get(`${API_BASE_URL}/epos?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Error details:', error.response ? error.response.data : error.message);
    console.error('Error fetching EPOS data:', error);
    throw error;
  }
};

export const fetchTerminals = async (page = 1, limit = 5) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/terminals?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Terminal data:', error);
    throw error;
  }
};

export const linkEposTerminal = async (terminalId, eposId) => {
  const url = `${API_BASE_URL}/link`;
  console.log(`Sending link request to: ${url}`);
  try {
    const response = await axios.post(url, {
      terminal_id: terminalId,
      epos_id: eposId
    });
    return response.data;
  } catch (error) {
    console.error('Error linking EPOS and Terminal:', error);
    throw error;
  } 
};

export const unlinkEposTerminal = async (terminalId, eposId) => {
  console.log('Unlinking EPOS from Terminal:', { terminalId, eposId });
  try {
    const response = await axios.post(`${API_BASE_URL}/unlink`, {
      terminal_id: terminalId,
      epos_id: eposId
    });
    console.log('Unlink response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error unlinking EPOS and Terminal:', error.response || error);
    throw error;
  }
};

export const searchEpos = async (query, page = 1, limit = 5) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/epos/search?query=${query}&page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Error searching EPOS data:', error);
    throw error;
  }
};

export const updateEpos = async (eposId, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/epos/${eposId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating EPOS:', error);
    throw error;
  }
};

export const deleteEpos = async (eposId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/epos/${eposId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting EPOS:', error);
    throw error;
  }
};


// Add SIMILAR functions for terminals

export const searchTerminal = async (query, page = 1, limit = 5) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/terminals/search?query=${query}&page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Error searching EPOS data:', error);
    throw error;
  }
};

export const updateTerminal = async (terminalId, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/terminals/${terminalId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating TERMINAL:', error);
    throw error;
  }
};

export const deleteTerminal = async (terminalId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/terminals/${terminalId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting Terminal:', error);
    throw error;
  }
};



export const addEpos = async (eposData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/epos`, eposData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding new EPOS:', error.response?.data || error.message);
    throw error;
  }
};

export const exportEposData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/epos/export`, {
      responseType: 'blob', // Important for file downloads
    });
    return response.data;
  } catch (error) {
    console.error('Error exporting EPOS data:', error);
    throw error;
  }
};



export const addTerminal = async (terminalData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/terminals`, terminalData);
    return response.data;
  } catch (error) {
    console.error('Error adding new Terminal:', error);
    throw error;
  }
};

export const exportTerminalData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/terminals/export`, {
      responseType: 'blob', // Important for file downloads
    });
    return response.data;
  } catch (error) {
    console.error('Error exporting Terminal data:', error);
    throw error;
  }
};


export const fetchAnalytics = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/analytics`);
    return response.data;
  } catch (error) {
    console.error('Error fetching analytics data:', error);
    throw error;
  }
};
